body {
  margin: 0;
  font-family: "Kanit";
}

.file-input {
  margin-bottom: 15px;
}

.dropbtn {
  border: none;
  cursor: pointer;
  font-family: "Kanit";
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  margin-left: 35px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  margin-left: 30px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 99;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #1c5bbd;
  color: #ffffff !important;
  border-radius: 3px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

div {
  font-family: "Kanit";
}

.css-1nmdiq5-menu {
  background-color: #fff !important;
  color: red !important;
}

span {
  font-family: "Kanit";
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #32bd02;
}

input:focus + .slider {
  box-shadow: 0 0 1px #32bd02;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

h6 {
  font-family: "Kanit" !important;
  color: #1c5bbd;
}

.MuiTableCell-root {
  font-family: "Kanit" !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a8a0a0;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a8a0a0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a8a0a0;
}

.image-gallery-play-button {
  display: none !important;
}

input,
select,
textarea {
  color: #1c5bbd !important;
  font-family: "Kanit" !important;
}

.react-datepicker__input-container {
  margin-left: 15px;
  margin-bottom: 10px;
}

input:focus,
textarea:focus,
select:focus {
  outline-color: #1c5bbd;
  color: #1c5bbd;
}

.modal-content {
  box-shadow: 6px 6px 6px 2px #1c5bbd;
}

.dzu-inputLabel {
  font-family: "Kanit" !important;
  color: #1c5bbd !important;
}

.dzu-dropzone {
  overflow: unset !important;
  width: 300px !important;
}

.dzu-previewImage {
  width: 100px !important;
  object-fit: fill;
}

.dzu-previewContainer {
  height: 50px !important;
}

.dzu-inputLabelWithFiles {
  font-family: "Kanit" !important;
  font-size: 15px !important;
  color: #1c5bbd !important;
}

.dzu-submitButton {
  font-family: "Kanit" !important;
  font-size: 15px !important;
}

.modal-header {
  background-color: #1c5bbd !important;
}

.close span {
  color: #ffffff !important;
  font-size: 35px !important;
}

.react-datepicker__header {
  background-color: #1c5bbd !important;
}

.react-datepicker__day-name {
  color: #ffffff !important;
}

.react-datepicker__current-month {
  color: #ffffff !important;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 20px;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: rgba(0, 0, 0, 0.44);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: rgba(0, 0, 0, 0.44);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*************************** start login screen ***************************/

.heard-login {
  display: flex;
  height: 40vh;
  background: url(../public/bg_2.png);
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bottom-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-bottom-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-login {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  margin-bottom: 15px;
}

.logo-login {
  margin-top: 12px;
  object-fit: cover;
}

.logo-phone {
  object-fit: cover;
  margin-right: 8px;
  margin-left: 15px;
}

.logo-phone-2 {
  object-fit: cover;
  margin-right: 8px;
}

.text-login-1 {
  margin-top: 12px;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 22px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-login-4 {
  margin-top: 14px;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 22px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.input-login-1 {
  width: 230px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 13px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.input-login-2 {
  width: 230px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 13px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 15px;
}

.input-login-3 {
  width: 420px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  padding-left: 20px;
  margin-top: 20px;
}

.btn-login {
  width: 230px;
  height: 40px;
  background: #1c5bbd;
  border: 1px solid #1c5bbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}

.btn-login:hover {
  background-color: #ffffff;
  color: #1c5bbd;
}

.btn-login-2 {
  width: 420px;
  height: 45px;
  background: #1c5bbd;
  border: 1px solid #1c5bbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}

.btn-login-2:hover {
  background-color: #ffffff;
  color: #1c5bbd;
}

.text-login-2 {
  font-weight: 400;
  font-size: 12px;
  color: #7f7f7f;
}

.text-login-3 {
  font-weight: 400;
  font-size: 14px;
  color: #7f7f7f;
  margin-top: 15px;
  font-family: "Kanit";
}

.text-login-3:hover {
  cursor: pointer;
}

.bg-login {
  display: flex;
  flex-direction: row;
}

.bg-login-left {
  display: flex;
  flex-direction: row;
  background-color: #1c5bbd;
  flex: 1;
  height: 100vh;
  align-items: center;
}

.bg-admin-left {
  display: flex;
  flex-direction: row;
  background-color: #1c5bbd;
  flex: 1;
  height: 100vh;
  align-items: center;
}

.contain-admin-center {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: red;
  font-family: "Kanit";
  font-size: 22px;
}

.bg-login-right {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  flex: 1;
  height: 100vh;
  justify-content: center;
}

.bg-login-desktop {
  width: 100%;
  height: 60%;
}

.text-login-5 {
  font-weight: 400;
  font-size: 16px;
  color: #a8a0a0;
  margin-top: 30px;
  margin-left: 10px;
  font-family: "Kanit";
}

.text-login-5:hover {
  cursor: pointer;
}

/*************************** end login screen ***************************/

/*************************** start register screen ***************************/

.container-register {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  align-items: center;
}

.bg-register {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
}

.heard-register {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heard-reportphone {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 45px;
  margin-top: 10px;
}

.heard-historypost {
  display: flex;
  flex-direction: column;
}

.text-register-1 {
  margin-top: 12px;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 22px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.input-register-1 {
  width: 220px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 12px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.select-register-1 {
  width: 235px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 12px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.text-register-2 {
  font-weight: 400;
  font-size: 12px;
  color: #7f7f7f;
  padding-top: 25px;
  padding-left: 15%;
}

/*************************** end register screen ***************************/

/*************************** start resetpassword screen ***************************/

.bg-resetpassword {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
}

.container-resetpassword {
  display: flex;
  flex-direction: column;
  margin-top: 90%;
  align-items: center;
}

.container-resetpassword-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heard-resetpassword {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-resetpassword-1 {
  margin-top: 12px;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 22px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.text-resetpassword-2 {
  margin-top: 12px;
  font-family: "Kanit";
  font-weight: 700;
  font-size: 19px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.text-resetpassword-3 {
  margin-top: 12px;
  font-family: "Kanit";
  font-weight: 700;
  font-size: 19px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.input-resetpassword-1 {
  width: 230px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.input-resetpassword-2 {
  width: 420px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 15px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.input-resetpassword-3 {
  width: 420px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 15px;
  color: #a8a0a0;
  padding-left: 10px;
}

.logo-resetpassword {
  margin-top: 25px;
  object-fit: contain;
  width: 100px;
  height: 110px;
}

/*************************** end resetpassword screen ***************************/

/*************************** start home screen ***************************/

.container-home-select-product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.heard-home {
  display: flex;
  flex-direction: column;
  background-color: #1c5bbd;
}

.heard-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.image-menu {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}

.image-menu-disable {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.image-menu-2 {
  border-radius: 5px;
  border: 2px solid #1c5bbd;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 100%;
}

.image-menu-3 {
  object-fit: cover;
}

.image-menu-4 {
  object-fit: contain;
  position: absolute;
  margin-top: 3px;
  margin-right: 3px;
}

.image-menu-4:hover {
  cursor: pointer;
}

.image-menu-5 {
  object-fit: contain;
}

.heard-menu-post {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content-home {
  display: flex;
  height: 80vh;
  flex-direction: column;
  background-color: #ffffff;
}

.logo-menu {
  object-fit: fill;
  margin-bottom: 5px;
}

.logo-image-post {
  object-fit: fill;
  margin-right: 10px;
}

.logo-image-profile {
  object-fit: contain;
  margin-top: 7px;
  margin-right: 10px;
}

.logo-toa {
  object-fit: cover;
}

.logo-image-home {
  object-fit: contain;
  border-radius: 7px;
}

.logo-image-home:hover {
  cursor: pointer;
}

.logo-clock {
  object-fit: cover;
  margin-left: 10px;
}

.logo-select {
  object-fit: cover;
  margin-top: 7px;
  margin-left: 5px;
}

.logo-profile {
  object-fit: cover;
  position: absolute;
  right: 80px;
}

.logo-profile:hover {
  cursor: pointer;
}

.logo-search {
  object-fit: cover;
}

.bg-menu {
  object-fit: fill;
  margin-top: 15px;
}

.text-home-1 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 13px;
  color: #1c5bbd;
  text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 7px;
  border-radius: 10px;
}

.input-home-1 {
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 17px;
  color: #a8a0a0;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  font-family: "Kanit";
}

.input-home-2 {
  height: 35px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 12px;
  color: #a8a0a0;
  margin-top: 15px;
  margin-right: 30px;
  margin-left: 30px;
  width: 100%;
}

.input-home-3 {
  height: 35px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 12px;
  color: #a8a0a0;
  margin-top: 15px;
  margin-left: 40px;
  margin-right: 40px;
}

.input-home-4 {
  height: 35px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 15px;
  color: #a8a0a0;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  font-family: "Kanit";
}

.input-home-5 {
  height: 35px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Kanit";
}

.container-home {
  display: flex;
  justify-content: center;
}

.container-home input[type="text"] {
  padding-left: 35px;
}

.container-home img {
  position: absolute;
  left: 25px;
  top: 97px;
}

.container-home-desktop {
  display: flex;
  justify-content: center;
}

.container-home-desktop input[type="text"] {
  padding-left: 55px;
}

.container-home-desktop img {
  position: absolute;
  left: 65px;
  top: 93px;
}

.container-home-desktop-2 {
  display: flex;
  flex-direction: row;
  padding-left: 60px;
  padding-right: 80px;
  padding-bottom: 15px;
}

.container-home-desktop-3 {
  flex: 0.25;
  height: 100vh;
}

.container-home-desktop-4 {
  flex: 0.75;
}

.container-home-desktop-5 {
  border-bottom: 1px solid rgba(181, 173, 173, 0.3);
  padding: 15px;
}

.container-home-desktop-6 {
  padding: 15px;
}

.container-home-desktop-7 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5px;
}

.container-home-desktop-8 {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 95px;
}

.container-home-desktop-8:hover {
  cursor: pointer;
}

.container-home-desktop-9 {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.container-home-desktop-10 {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.container-home-desktop-11 {
  display: flex;
  flex-direction: row;
}

.container-home-desktop-11:hover {
  cursor: pointer;
}

.container-home-mobile-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container-home-mobile-1:hover {
  cursor: pointer;
}

.container-home-mobile-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-home-mobile-2:hover {
  cursor: pointer;
}

.container-home-search {
  display: flex;
  justify-content: center;
  padding-left: 45px;
  padding-right: 65px;
  padding-bottom: 15px;
}

.container-home-search input[type="text"] {
  padding-left: 45px;
}

.container-home-search img {
  position: absolute;
  left: 77px;
  top: 110px;
}

.container-home-search-2 {
  display: flex;
  margin-bottom: 15px;
}

.container-home-search-2 input[type="text"] {
  padding-left: 35px;
}

.container-home-search-2 img {
  position: absolute;
  left: 45px;
  top: 25px;
  z-index: 2;
}

.container-home-search-3 {
  display: flex;
  justify-content: center;
  padding-left: 45px;
  padding-right: 65px;
  padding-bottom: 15px;
}

.container-home-search-3 input[type="text"] {
  padding-left: 45px;
}

.container-home-search-3 img {
  position: absolute;
  left: 77px;
  top: 100px;
}

.text-home-2 {
  margin-top: 10px;
  margin-left: 15px;
  font-family: "Kanit";
  font-weight: bold;
  font-size: 18px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.container-menu-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.container-home-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-home-3 {
  font-family: "Kanit";
  font-size: 13px;
  color: #a8a0a0;
  margin-top: 8px;
}

.body-home {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  margin-left: 20px;
  overflow-x: scroll;
}

.body-home-2 {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-left: 20px;
}

.container-home-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #ffffff;
  border: 0.2px solid #dcd4d4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right: 12px;
  padding-top: 10px;
  margin-top: 5px;
}

.container-home-3 {
  display: flex;
  flex-direction: row;
  width: 330px;
}

.container-home-4 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: #ffffff;
  background-color: #1c5bbd;
  height: 45px;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.container-home-4:hover {
  cursor: pointer;
}

.container-home-5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-home-6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #b5adad;
  border-radius: 10px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 17px;
  top: 55px;
  z-index: 1;
}

.container-home-7 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.container-home-8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #b5adad;
  border-radius: 10px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 85px;
  top: 60px;
  z-index: 1;
}

.container-home-9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #b5adad;
  border-radius: 10px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 90px;
  top: 190px;
}

.container-home-10 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #b5adad;
  border-radius: 10px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 90px;
  top: 190px;
}

.text-home-4 {
  font-family: "Kanit";
  font-size: 12px;
  color: #1c5bbd;
  margin-top: 3px;
}
.text-home-5 {
  font-family: "Kanit";
  font-size: 15px;
}

.heard-home-2 {
  border-style: dotted;
  border: 1px dashed #1c5bbd;
  border-radius: 10px;
  padding-bottom: 7px;
  margin: 15px;
}

.heard-home-3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
}

.heard-home-4 {
  border-style: dotted;
  border: 1px dashed #1c5bbd;
  border-radius: 10px;
  padding-bottom: 7px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.heard-home-5 {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.heard-home-6 {
  border-style: dotted;
  border: 1px dashed #1c5bbd;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.heard-home-7 {
  display: flex;
  flex-direction: column;
  flex: 0.2;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.5%;
}

.heard-home-8 {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.heard-home-9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0.35;
  border-style: dotted;
  border-left: 1px dashed #1c5bbd;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.heard-home-10 {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}

.heard-home-11 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heard-home-12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}

.heard-home-12:hover {
  cursor: pointer;
}

.heard-home-13 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.5;
  border-style: dotted;
  border-left: 1px dashed #1c5bbd;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  padding-left: 40px;
}

.heard-home-14 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.5;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.heard-home-15 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heard-home-16 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.3;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

.text-home-6 {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 5px;
  font-family: "Kanit";
  font-weight: 700;
  font-size: 15px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #b5adad;
}

.content-text-home {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding-top: 10px;
}

.text-home-7 {
  font-family: "Kanit";
  font-size: 13px;
  color: #1c5bbd;
  margin-left: 15px;
}

.text-home-8 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 17px;
  color: #1c5bbd;
}

.text-home-9 {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 14px;
  color: #bd1c1c;
}

.text-home-9:hover {
  cursor: pointer;
}

.text-home-10 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 12px;
  color: #858585;
  margin-top: 5px;
}

.text-home-11 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  margin-left: 35px;
}

.text-home-11:hover {
  color: #cccccc;
  cursor: pointer;
}

.text-home-12 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 20px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-home-13 {
  font-family: "Kanit";
  font-weight: 300;
  font-size: 16px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-home-14 {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.25);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-home-14:hover {
  cursor: pointer;
}

.text-home-15 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 20px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #1c5bbd;
}

.text-home-16 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.62);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-home-17 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  color: #858585;
  margin-top: 5px;
  flex: 0.5;
}

.text-home-18 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.62);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

.text-home-19 {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 16px;
  color: #858585;
}

.text-home-20 {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
  color: #1c5bbd;
}

.text-home-20:hover {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
  color: #858585;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-home-21 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}

.text-home-22 {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.25);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-left: 30px;
}

.text-home-22:hover {
  cursor: pointer;
}

.text-home-23 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 20px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #1c5bbd;
  margin-left: 30px;
}

.text-home-24 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 14px;
  color: #858585;
  margin-top: 5px;
  text-align: justify;
  text-justify: inter-word;
  margin-right: 5px;
  margin-left: 5px;
}

.text-home-25 {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 10px;
  color: rgba(0, 0, 0, 0.62);
  margin-left: 40px;
  margin-top: 10px;
}

.text-home-26 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  padding: 10px;
  height: 25px;
  border-radius: 9999px;
  font-family: "Kanit";
  font-weight: 300;
  font-size: 15px;
  background-color: #e61205;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-home-27 {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 26px;
  border-radius: 9999px;
  font-family: "Kanit";
  font-weight: 300;
  font-size: 15px;
  background-color: #e61205;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-left: 3px;
}

.text-home-28 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  width: 50px;
  border-radius: 15px;
  font-family: "Kanit";
  font-weight: 300;
  font-size: 20px;
  background-color: #e61205;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-home-29 {
  margin-top: 10px;
  margin-left: 15px;
  padding-bottom: 5px;
  font-family: "Kanit";
  font-weight: 700;
  font-size: 15px;
  color: #1c5bbd;
}

.text-home-30 {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.25);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-left: 30px;
}

.text-home-30:hover {
  cursor: pointer;
}

.text-home-31 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #1c5bbd;
  margin-left: 30px;
}

.text-home-32 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  color: #858585;
}

.text-home-33 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  color: #858585;
  margin-top: 30px;
}

.text-home-34 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  color: #858585;
  margin-top: 5px;
  margin-left: 10px;
}

.text-home-35 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  color: #858585;
  margin-left: 10px;
}

.text-home-36 {
  font-family: "Kanit" !important;
  color: #1c5bbd !important;
}

.btn-filerter-home-1 {
  background: #d91a15;
  border: 1px solid #d91a15;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}
.btn-filerter-home-1:hover {
  background-color: #ffffff;
  color: #d91a15;
}

.heard-home-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1c5bbd;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn-post-home-1 {
  margin-left: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-weight: 700;
  font-size: 20px;
  color: #1c5bbd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}

.btn-post-home-1:hover {
  color: #ffffff;
  background: #cccccc;
}

.btn-post-home-2 {
  background: #ffffff;
  border: 1px solid #1c5bbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #1c5bbd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: "Kanit";
  width: 280px;
}

.btn-post-home-2:hover {
  color: #ffffff;
  background: #cccccc;
  cursor: pointer;
}

.btn-post-home-3 {
  background: #b5adad;
  border: 1px solid #b5adad;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 20px;
  font-family: "Kanit";
  width: 280px;
}

.btn-post-home-3:hover {
  background: red;
}

.btn-post-home-4 {
  background: #7ba859;
  border: 1px solid #7ba859;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 20px;
  font-family: "Kanit";
  width: 280px;
}

.btn-post-home-5 {
  background: #ffffff;
  border: 1px solid #1c5bbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #1c5bbd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: "Kanit";
  width: 280px;
  margin-top: 30px;
}

.btn-post-home-5:hover {
  color: #ffffff;
  background: #cccccc;
  cursor: pointer;
}

.content-home-mobile-1 {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 0.2px solid #dcd4d4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right: 12px;
  margin-bottom: 15px;
}

.content-home-mobile-2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}

.content-home-mobile-3 {
  display: flex;
  flex-direction: row;
}

.content-home-mobile-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-home-mobile-5 {
  background: #ffffff;
  border: 1px solid #1c5bbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  color: #1c5bbd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: "Kanit";
  width: 280px;
}

.content-home-mobile-6 {
  background: #b5adad;
  border: 1px solid #b5adad;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-family: "Kanit";
  width: 280px;
}

.content-home-mobile-6:hover {
  background: red;
}

.content-home-mobile-7 {
  background: #7ba859;
  border: 1px solid #7ba859;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  font-family: "Kanit";
  width: 280px;
  margin-bottom: 15px;
}

.content-home-desktop-1 {
  display: flex;
  flex-direction: column;
}

.content-home-desktop-2 {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  justify-content: center;
  padding-bottom: 10px;
}

.content-home-desktop-3 {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}

.content-home-desktop-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-home-desktop-text-1 {
  font-family: "Kanit";
  font-weight: 400;
  font-size: 18px;
  color: #1c5bbd;
  margin-right: 10px;
}

/*************************** end home screen ***************************/

/*************************** start add post screen ***************************/

.container-post-image-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-post-image {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #bd1c1c;
}

.heard-post {
  display: flex;
  flex-direction: column;
  background-color: #1c5bbd;
}

.content-post {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.icon-post {
  object-fit: cover;
}

.image-upload {
  display: flex;
  justify-content: center;
}

.image-upload input {
  display: none;
}

.text-post-1 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  color: #737070;
  margin-left: 16%;
  margin-top: 15px;
}

.text-post-2 {
  font-weight: 700;
  font-size: 16px;
  color: #737070;
  margin-left: 16%;
  margin-top: 15px;
  font-family: "Kanit";
}

.input-post {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-post-1 {
  width: 235px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.input-post-1-error {
  width: 235px;
  height: 40px;
  background: #ffffff;
  border: 2px solid red;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.select-post-1 {
  width: 235px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.select-post-2 {
  width: 235px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  padding-left: 10px;
  margin-top: 20px;
}

.text-post-3 {
  font-weight: 700;
  font-size: 16px;
  color: #737070;
  margin-left: 16%;
  margin-top: 15px;
  font-family: "Kanit";
}

.body-select-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.body-select-2 {
  padding-left: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 226px;
  background: #1c5bbd;
  border-radius: 15px;
}

.body-select-3 {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.body-select-4 {
  padding-left: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #d9d9d9;
  border-radius: 15px;
  width: 226px;
}

.text-post-4 {
  font-weight: 400;
  font-size: 14px;
  color: #a8a0a0;
  font-family: "Kanit";
}

.body-select-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.textarea-post-1 {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  width: 226px;
  height: 89px;
  padding: 12px;
}

.body-select-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.body-select-7 {
  display: flex;
  flex-direction: row;
}

.text-post-5 {
  color: #1c5bbd;
  font-size: 14px;
  margin-right: 5px;
  font-family: "Kanit";
}

.text-post-6 {
  color: #1c5bbd;
  font-weight: bold;
  font-size: 14px;
  margin-right: 5px;
  font-family: "Kanit";
}

.body-select-8 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-post-1 {
  width: 230px;
  height: 40px;
  background: #1c5bbd;
  border: 1px solid #1c5bbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}

.btn-post-1:hover {
  background-color: #ffffff;
  color: #1c5bbd;
}

.btn-post-2 {
  width: 230px;
  height: 40px;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}

.btn-post-2:hover {
  background-color: #ffffff;
  color: #d9d9d9;
}

.btn-post-3 {
  width: 100px;
  height: 40px;
  background: #db8a2f;
  border: 1px solid #db8a2f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
  margin-right: 5px;
}

.btn-post-3:hover {
  background-color: #ffffff;
  color: #db8a2f;
}

.btn-post-4 {
  width: 100px;
  height: 40px;
  background: #d91a15;
  border: 1px solid #d91a15;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}

.btn-post-4:hover {
  background-color: #ffffff;
  color: #d91a15;
}

.btn-post-5 {
  width: 100px;
  height: 40px;
  background: #1c5bbd;
  border: 1px solid #1c5bbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
  margin-bottom: 10px;
}

.btn-post-5:hover {
  background-color: #ffffff;
  color: #1c5bbd;
}

.btn-post-6 {
  width: 100px;
  height: 40px;
  background: green;
  border: 1px solid green;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
  margin-bottom: 10px;
  margin-left: 10px;
}

.btn-post-6:hover {
  background-color: #ffffff;
  color: green;
}

.body-post-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 95%;
  z-index: 0;
}

.bg-post {
  position: absolute;
  z-index: -1;
  top: 13%;
  width: 100%;
}

.text-post-7 {
  font-family: "Kanit";
  font-weight: bold;
  font-size: 22px;
  color: #1c5bbd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-post-8 {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  font-family: "Kanit";
}

.text-post-9 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  color: #737070;
}

.logo-post {
  margin-top: 20px;
  object-fit: contain;
  width: 100px;
  height: 110px;
}

.container-post-image {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding-left: 15px;
  padding-right: 15px;
}

/*************************** end add post screen ***************************/

/*************************** start add list store screen ***************************/
.container-liststore-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container-liststore-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.container-liststore-3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-liststore-1 {
  font-family: "Kanit";
  font-weight: 700;
  font-size: 16px;
  width: 100px;
  color: #737070;
}
.input-liststore-1 {
  height: 35px;
  width: 250px;
  background: #ffffff;
  border: 1px solid #b5adad;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Kanit";
  padding-left: 10px;
}
.input-liststore-2 {
  height: 35px;
  width: 250px;
  background: #ffffff;
  border: 2px solid red;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 14px;
  color: #a8a0a0;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Kanit";
  padding-left: 10px;
}
.content-liststore-1 {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 15px;
}
.btn-liststore-1 {
  width: 230px;
  height: 40px;
  background: #d91a15;
  border: 1px solid #d91a15;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}
.btn-liststore-1:hover {
  background-color: #ffffff;
  color: #d91a15;
}
.btn-liststore-2 {
  width: 230px;
  height: 40px;
  background: #1c5bbd;
  border: 1px solid #1c5bbd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kanit";
}
.btn-liststore-2:hover {
  background-color: #ffffff;
  color: #1c5bbd;
}
/*************************** end add list store screen ***************************/

/* start width 320 */
@media screen and (min-width: 320px) {
  #login-desktop {
    display: none;
  }

  #home-desktop {
    display: none;
  }

  #forgotpassword-desktop {
    display: none;
  }

  #newspassword-desktop {
    display: none;
  }

  #post-desktop {
    display: none;
  }

  #historypost-desktop {
    display: none;
  }

  .bottom-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-login {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    margin-bottom: 15px;
  }
}

/* end width 320 */

/* start width 375 */
@media screen and (min-width: 375px) {
  #login-desktop {
    display: none;
  }

  #home-desktop {
    display: none;
  }

  #forgotpassword-desktop {
    display: none;
  }

  #newspassword-desktop {
    display: none;
  }

  #post-desktop {
    display: none;
  }

  #historypost-desktop {
    display: none;
  }

  .bottom-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-login {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    padding-left: 20%;
    margin-bottom: 15px;
  }

  .text-register-2 {
    font-weight: 400;
    font-size: 12px;
    color: #7f7f7f;
    padding-top: 25px;
    padding-left: 20%;
  }
}
/* end width 375 */

/* start width 425 */
@media screen and (min-width: 425px) {
  #login-desktop {
    display: none;
  }

  #home-desktop {
    display: none;
  }

  #forgotpassword-desktop {
    display: none;
  }

  #newspassword-desktop {
    display: none;
  }

  #post-desktop {
    display: none;
  }

  #historypost-desktop {
    display: none;
  }

  .bottom-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-login {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    padding-left: 24%;
    margin-bottom: 15px;
  }

  .text-register-2 {
    font-weight: 400;
    font-size: 12px;
    color: #7f7f7f;
    padding-top: 25px;
    padding-left: 24%;
  }
}
/* end width 425 */

/* start width 768 */
@media screen and (min-width: 768px) {
  #login-desktop {
    display: none;
  }

  #home-desktop {
    display: none;
  }

  #forgotpassword-desktop {
    display: none;
  }

  #newspassword-desktop {
    display: none;
  }

  #post-desktop {
    display: none;
  }

  #historypost-desktop {
    display: none;
  }

  .bottom-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-login {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    padding-left: 36%;
    margin-bottom: 15px;
  }

  .text-register-2 {
    font-weight: 400;
    font-size: 12px;
    color: #7f7f7f;
    padding-top: 25px;
    padding-left: 36%;
  }

  .container-resetpassword {
    display: flex;
    flex-direction: column;
    margin-top: 50%;
    align-items: center;
  }
}
/* end width 768 */

/* start width 1024 */
@media screen and (min-width: 1024px) {
  #login-mobile {
    display: none;
  }

  #forgotpassword-mobile {
    display: none;
  }

  #newspassword-mobile {
    display: none;
  }

  #home-mobile {
    display: none;
  }

  #post-mobile {
    display: none;
  }

  #historypost-mobile {
    display: none;
  }

  #login-desktop {
    display: block;
  }

  #home-desktop {
    display: block;
  }

  #forgotpassword-desktop {
    display: block;
  }

  #newspassword-desktop {
    display: block;
  }

  #post-desktop {
    display: block;
  }

  #historypost-desktop {
    display: block;
  }
}
/* end width 1024 */

/* start width 1425 */
@media screen and (min-width: 1425px) {
  .container-home-9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #b5adad;
    border-radius: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 90px;
    top: 190px;
  }
}
/* end width 1425 */

/* start width 1905 */
@media screen and (min-width: 1905px) {
  .container-home-9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #b5adad;
    border-radius: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 135px;
    top: 190px;
  }
}
/* end width 1905 */
